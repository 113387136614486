import React, { useRef } from "react";
import _ from "lodash";

import { Divider, Grid, Typography, colors } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import ContainerLoading from "../../../components/Loading/ContainerLoading";

import HttpContext from "../../../contexts/HTTP/HttpContext";

function Home(props) {
  const theme = useTheme();

  const containerRef = useRef(null);

  return (
    <Grid
      ref={containerRef}
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4">Home</Typography>
        <Typography>Welcome to your kiosk dashboard</Typography>
      </div>

      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      {/* <ContainerLoading show="false" /> */}
    </Grid>
  );
}

export default Home;
