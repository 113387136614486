import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // or any other adapter like AdapterDateFns
import dayjs from "dayjs";

import { useTheme } from "@mui/material/styles";

import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerLoading from "../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../utils/misc";

import BagtagLogRow from "./BagtagLogs/BagtagLogRow";
import BagtagLogCodeDropdown from "./BagtagLogs/BagtagLogCodeDropdown";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

let env = require("../../../env");
const APP_URL = env.REACT_SCOCKETIO_URL;

function BagtagLogs() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);

  const [filterBagtag, setFilterBagtag] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [filterKioskID, setFilterKioskID] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(dayjs());
  const [filterEndDate, setFilterEndDate] = useState(dayjs());

  const [allLogs, setAllLogs] = useState([]);

  const refresh = async () => {
    // Fetch and display socket info
    setLoading(true);

    let payload = {
      bagtag: filterBagtag,
      locationCode: filterCode,
      kioskID: filterKioskID,
      startTime: filterStartDate.toISOString(),
      endTime: filterEndDate.toISOString()
      //startTime: "",
      //endTime: ""
    };

    console.log("payload", payload);

    const res = await post("/kiosk/getBagtag/", payload);
    console.log("res", res);

    if (res.status == 200) {
      setAllLogs(res.data);
    }

    setLoading(false);
  };

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">Bagtag Logs [{allLogs.length}]</Typography>
          <Typography>Bagtags created by Kiosk</Typography>
        </div>
        <div>
          <Button variant="contained" onClick={() => refresh()}>
            Refresh Info
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <TextField
          id="bts"
          label="Bagtag Search"
          value={filterBagtag}
          onChange={event => {
            let newText = event.target.value;
            newText = newText.toUpperCase().trim();
            setFilterBagtag(newText);
          }}
        />
        <BagtagLogCodeDropdown
          value={filterCode}
          onChange={(e, val) => {
            setFilterCode(val);
          }}
        />
        <TextField
          id="kis"
          label="KioskID Search"
          value={filterKioskID}
          onChange={event => {
            let newText = event.target.value;
            newText = newText.trim();
            setFilterKioskID(newText);
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Date Filter"
            value={filterStartDate}
            onChange={newValue => {
              console.log("DateTimePicker", newValue);
              setFilterStartDate(newValue);
            }}
          />
          <DateTimePicker
            label="Date Filter"
            value={filterEndDate}
            onChange={newValue => {
              console.log("DateTimePicker", newValue);
              setFilterEndDate(newValue);
            }}
          />
        </LocalizationProvider>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">bagtag</TableCell>
              <TableCell align="left">timeStamp</TableCell>
              <TableCell align="left">collectorCode</TableCell>
              <TableCell align="left">numBags</TableCell>
              <TableCell align="left">numLabels</TableCell>
              <TableCell align="left">kioskID</TableCell>
              {/* <TableCell align="left">subscriptionID</TableCell> */}
              {/* <TableCell align="left">locationCode</TableCell> */}
              {/* <TableCell align="left">locationName</TableCell>
              <TableCell align="left">locationAddress</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {allLogs.map((row, index) => (
              <BagtagLogRow row={row} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default BagtagLogs;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
