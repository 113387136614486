import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";

import AlertDialog from "../../../../components/Dialogs/AlertDialog";
import ContainerLoading from "../../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../../utils/misc";

import { KEY_NAME_REGEX } from "../../../../constants";

import HttpContext from "../../../../contexts/HTTP/HttpContext";

function APIKeys() {
  const theme = useTheme();
  const { get } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [showCreateKeyDialog, setShowCreateKeyDialog] = useState(false);
  const [createKeyName, setCreateKeyName] = useState("");

  const [showKeyDialog, setShowKeyDialog] = useState(false);
  const [keyForTempDisplay, setKeyForTempDisplay] = useState("");

  const [showDeleteKeyDialog, setShowDeleteKeyDialog] = useState(false);
  const [keyObjToDelete, setKeyObjToDelete] = useState(null);

  const [allKeys, setAllKeys] = useState([]);

  const loadKeys = async () => {
    setLoading(true);

    const res = await get("/getUsersActiveAPIKeys");
    if (res.status === 200) {
      const keys = _.get(res, "data.keys");
      setAllKeys(keys || []);
    }

    setLoading(false);
  };

  const generateAPIKey = async () => {
    setLoading(true);

    setCreateKeyName("");
    setShowCreateKeyDialog(false);

    const res = await get("/generateAPIKey", { name: createKeyName });
    if (res.status === 200) {
      const key = _.get(res, "data.key");
      if (key) {
        setKeyForTempDisplay(key);
        setShowKeyDialog(true);
      }
    }

    await loadKeys();

    setLoading(false);
  };

  const handleDeleteKey = async () => {
    if (!keyObjToDelete) return;

    setLoading(true);

    await get("/deleteAPIKey", { name: _.get(keyObjToDelete, "name") });
    await loadKeys();

    setKeyObjToDelete(null);
    setShowDeleteKeyDialog(false);

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await loadKeys();
    })();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">API Keys</Typography>
          <Typography>View and delete your API keys</Typography>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => setShowCreateKeyDialog(true)}
            disabled={loading}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Create API Key
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Key</TableCell>
              <TableCell align="left">Expires</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allKeys.map(row => (
              <TableRow
                key={row.name}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">************</TableCell>
                <TableCell align="left">
                  {formatDate(row.expires, "MMM DD, YYYY")}
                </TableCell>
                <TableCell align="center">
                  {row.active ? (
                    <Circle style={{ color: "lightgreen" }} />
                  ) : (
                    <Circle style={{ color: "red" }} />
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    disabled={!row.active}
                    onClick={() => {
                      setShowDeleteKeyDialog(true);
                      setKeyObjToDelete(row);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        open={showKeyDialog}
        handleClose={() => {
          setShowKeyDialog(false);
          setKeyForTempDisplay("");
        }}
        title="Your API Key"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Here is your API key:
        </Typography>
        <Paper elevation={3} sx={{ p: 2, marginBottom: theme.spacing(2) }}>
          <Typography
            variant="body2"
            component="code"
            style={{ wordWrap: "break-word" }}
          >
            {keyForTempDisplay}
          </Typography>
        </Paper>
        <Typography style={{ color: "red" }}>
          WARNING: Once you close this window, you will not be able to see this
          API key again. Make sure you note it down and keep it in a safe place.
        </Typography>
      </AlertDialog>
      <AlertDialog
        open={showCreateKeyDialog}
        handleConfirm={generateAPIKey}
        handleClose={() => setShowCreateKeyDialog(false)}
        title="Create your API key"
        confirmButtonName="Confirm"
        disableConfirm={
          !isKeyNameValid(createKeyName) ||
          isKeyNameDuplicate(allKeys, createKeyName)
        }
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Enter a name for your API key below.{" "}
          <span style={{ color: "grey" }}>
            (Letters, numbers, hyphens, and spaces are allowed)
          </span>
          :
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          id="name"
          name="name"
          label="Name"
          value={createKeyName}
          onChange={e => setCreateKeyName(e.target.value)}
          style={{ marginBottom: theme.spacing(2) }}
          error={
            !isKeyNameValid(createKeyName) ||
            isKeyNameDuplicate(allKeys, createKeyName)
          }
          helperText={getKeyNameHelperText(allKeys, createKeyName)}
        />
      </AlertDialog>
      <AlertDialog
        open={showDeleteKeyDialog}
        handleConfirm={handleDeleteKey}
        handleClose={() => setShowDeleteKeyDialog(false)}
        title="Delete your API key"
        confirmButtonName="Confirm"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Are you sure you would like to delete the API key{" "}
          <b>{_.get(keyObjToDelete, "name", "")}</b>?
        </Typography>
        <Typography style={{ marginBottom: theme.spacing(2), color: "red" }}>
          WARNING: This key will no longer be usable.
        </Typography>
      </AlertDialog>
    </Grid>
  );
}

export default APIKeys;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
