import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import KioskRowErrorLink from "./KioskRowErrorLink";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

function KioskRow(props) {
  const { row, showDisabledEntries } = props;

  console.log("KioskRow row", row.locationCode, row);

  if (showDisabledEntries == false && row.active == false) {
    return <></>;
  }

  return (
    <TableRow
      key={row.locationCode}
      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.locationCode}
      </TableCell>
      <TableCell
        align="left"
        style={row.active ? {} : { textDecoration: "line-through" }}
      >
        {row.subscriptionID}
      </TableCell>
      <TableCell align="left">{row.counter}</TableCell>
      <TableCell align="left">
        {/* <IconButton
          onClick={() => {
            // Just temporary disabled
            // loadDetails(row._id);
            // setShowDetailsDialog(true);
          }}
        >
          {" "}
          <InfoIcon />
        </IconButton> */}
        {row.locationAddress}
      </TableCell>

      <TableCell align="left">{row.counter}</TableCell>
      <TableCell align="left">{row.lastSynced}</TableCell>
      <TableCell align="left"><KioskRowErrorLink errorLogRef={row.errorLogRef} onClickHandler={()=> {}}/></TableCell>

      <TableCell align="center">
        {row.active ? (
          <Circle style={{ color: "lightgreen" }} />
        ) : (
          <Circle style={{ color: "red" }} />
        )}
      </TableCell>
      {/* <TableCell align="center">
        <IconButton
          disabled={!row.active}
          onClick={() => {
            //setShowDeleteKeyDialog(true);
            //setKeyObjToDelete(row);
          }}
        >
          <Delete />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
}

export default KioskRow;
