import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

function UpdateLogRow(props) {
  const { row } = props;

  return (
    <TableRow key={row.socketID}>
      <TableCell align="left">{row.socketID}</TableCell>
      <TableCell align="left">{JSON.stringify(row.data)}</TableCell>
    </TableRow>
  );
}

export default UpdateLogRow;
