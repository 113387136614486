import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import { formatDate } from "../../../../utils/misc";

import moment from "moment";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

function UpdateLogRow(props) {
  const { row } = props;

  return (
    <TableRow key={row._id} id={row._id}>
      <TableCell align="left">
        {/* {formatDate(row.timeStarted, "YYYY-MMM-DD HH:mm:ss")} */}
        {/* {formatDate(row.timeStarted, "hh:mma MMM-DD, YYYY")}
        {"_"} */}
        {moment
          .utc(row.timeStarted)
          .local()
          .format("hh:mma MMM-DD, YYYY Z")}
      </TableCell>
      <TableCell align="left">
        {formatDate(row.timeCompleted, "YYYY-MMM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="left">{row.inboundCode}</TableCell>
      <TableCell align="left">{row.inboundData}</TableCell>
      <TableCell align="left">{row.error}</TableCell>
      <TableCell align="left">{row.status}</TableCell>
    </TableRow>
  );
}

export default UpdateLogRow;
