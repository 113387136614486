import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

function KioskRowErrorLink(props) {
  const { errorLogRef, onClickHandler } = props;

  console.log("KioskRowErrorLink errorLogRef",errorLogRef);

  if (errorLogRef === undefined || errorLogRef === null) {
    return <></>;
  }

  return (
    <Button
      //key={errorLogRef}
       variant="outlined"
        color="error"
      //onClick={() => onClickHandler(errorLogRef)}
      //onClick={() => navigate("/dashboard/SyncUpdateLogs#" + errorLogRef)}
      //href={"/dashboard/SyncUpdateLogs#" + errorLogRef}
      href={"/dashboard/SyncUpdateLogs/" + errorLogRef}
      
    >
      Error
    </Button>
  );
}

export default KioskRowErrorLink;
