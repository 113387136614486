import React, { useContext, useEffect, useState } from "react";
import HttpContext from "../../../../contexts/HTTP/HttpContext";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Autocomplete
} from "@mui/material";

function BagtagLogCodeDropdown({ value, onChange }) {
  const [locationList, setLocationList] = useState([]);
  const { get, post } = useContext(HttpContext);

  useEffect(() => {
    async function getDropLoc() {
      const res = await get("/kiosk/getAllSubscription");
      if (res.status === 200) {
        let locationCodes = res.data.map(location => location.locationCode);
        setLocationList(locationCodes);
      }
    }

    getDropLoc();
  }, []);

  return (
    <div style={{ minWidth: "10rem" }}>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          id="cblocationoptions"
          options={locationList}
          onChange={onChange}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              name="name"
              label="Location Code"
            />
          )}
        />
      </FormControl>
    </div>
  );
}

export default BagtagLogCodeDropdown;
