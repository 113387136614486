import React, { useContext, useEffect, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function VisibilityIcon({ visible, style, props }) {
  if (visible) {
    return <Visibility style={style} props={props} />;
  } else {
    return <VisibilityOff style={style} props={props} />;
  }
}

export default VisibilityIcon;
