import React, { useState, useEffect } from "react";
import _ from "lodash";

import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TextField
} from "@mui/material";

function EditDialogTableBody({ key, value, updateData, updateValueFunc }) {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    console.log("updateData updated");
    if (updateData !== null) {
      setSettings(updateData.settings);
    }
  }, [updateData]);

  console.log("EditDialogTableBody", updateData);

  if (updateData === null) {
    console.log("EditDialogTableBody returning <></>");
    return <></>;
  }

  
  console.log("EditDialogTableBody returning body");

  Object.keys(settings).map(key => {
    console.log("EditDialogTableBody returning key", key)
  }
  );

  return (
    <TableBody>
      {Object.keys(settings).map(key => (
        <TableRow key={key}>
          <TableCell align="left">{key}</TableCell>
          <TableCell align="left">
            {/* {editDialogData.setting[key]} */}
            <TextField
              value={settings[key]}
              onChange={event => {
                console.log("event", event, key);
                updateValueFunc(key, event.target.value);
              }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

export default EditDialogTableBody;
